<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.informationPartner") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button v-show="edit" class="btn btn-secondary">
          {{ $t("general.save") }}
        </button>
        <button v-show="!edit" class="btn btn-primary" @click="savePartner">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusyTotal" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold">{{ $t("partner.companyData") }}:</h5>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.number") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="partner.number"
                type="text"
                class="form-control"
                readonly
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.logo") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <ImageUploadCropper
              aspect-ratio=""
              :default-image="companyLogo"
              :upload-button-text="$t('companyData.uploadLogo')"
              :crop-button-text="$t('companyData.cropLogo')"
              :remove-button-text="$t('companyData.removeLogo')"
              @cropMode="edit = $event"
              @fileUpdated="files = $event"
              @fileRemoved="fileRemoved()"
            >
            </ImageUploadCropper>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.name") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input v-model="partner.name" type="text" class="form-control" />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.nameShort") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <input
              v-model="partner.name_short"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.language") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <v-select
                v-model="partner.language.id"
                class="form-control"
                :items="languageOptions"
                item-text="label"
                item-value="id"
                :menu-props="{ offsetY: true }"
              />
              <div class="input-group-append">
                <span class="input-group-text border-0">
                  <i class="fal fa-earth-europe icon-lg" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactPerson") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <input
                v-model="partner.contactPerson.name"
                type="text"
                class="form-control"
                readonly
              />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="fal fa-lock icon-lg"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import ImageUploadCropper from "@/components/Admins/Media/ImageUploadCropper";
import Partners from "@/components/Admins/Partners/partners";
import Media from "@/components/Admins/Media/media";
import Swal from "sweetalert2";
import { bus } from "@/main";
import Languages from "@/components/General/Languages/languages";
import { UPDATE_SELECTED_PARTNER } from "@/core/services/store/switchArea.module";
import store from "@/core/services/store";

export default {
  components: {
    ImageUploadCropper
  },
  props: ["partner", "isBusyParent"],
  data() {
    return {
      files: [],
      edit: false,
      companyLogo: "",
      languageOptions: [],
      isBusy: false,
      oldLogoId: null
    };
  },
  computed: {
    isBusyTotal: function () {
      return this.isBusy || this.isBusyParent !== 0;
    }
  },
  watch: {
    partner: function () {
      this.loadPartnerData();
    }
  },
  mounted() {
    this.getLanguages();
    this.loadPartnerData();
  },
  methods: {
    async loadPartnerData() {
      this.companyLogo = "";

      if (this.partner.logo_id !== undefined && this.partner.logo_id !== null) {
        this.isBusy = true;
        Media.get(this.partner.logo_id)
          .then(mediaResponse => {
            this.companyLogo = mediaResponse.data.data.link;
            this.isBusy = false;
          })
          .catch(error => {
            this.$swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      }
    },
    async savePartner() {
      this.isBusy = true;
      let self = this;
      let oldLogoId = self.oldLogoId;

      if (this.files.length) {
        let mediaData = new FormData();
        mediaData.append("files[]", this.files[0].file);
        mediaData.append("description[]", "Logo - " + self.partner.name);
        await Media.store(mediaData)
          .then(response => {
            if (response.data.data.success) {
              self.partner.logo_id = response.data.data.uploaded[0].id;
            }
          })
          .catch(error => {
            Swal.fire({
              title: this.$t("general.caution"),
              text: error.response.data.message,
              icon: "error"
            });
            this.isBusy = false;
          });
      }
      const data = {
        name: self.partner.name,
        name_short: self.partner.name_short,
        language_id: self.partner.language.id,
        logo_id: self.partner.logo_id
      };

      Partners.update(self.partner.id, data)
        .then(() => {
          if (this.files.length || !this.partner.logo_id) {
            self.deleteLogo(oldLogoId);
          }

          this.loadPartnerData();
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.updateStore(self.partner.id);
          bus.$emit("partnerUpdated");
          this.isBusy = false;
        })
        .catch(error => {
          console.log(error);
          self.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          self.isBusy = false;
        });
    },
    deleteLogo(logoId = this.partner.logo_id) {
      if (logoId !== null) {
        Media.delete(logoId);
      }
    },
    fileRemoved() {
      this.files = [];
      this.companyLogo = false;
      this.partner.logo_id = null;
    },
    getLanguages() {
      Languages.getAll()
        .then(response => {
          this.languageOptions = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    },
    copyText(text) {
      this.$copyText(text);
      this.$toast.fire({
        icon: "info",
        title: this.$t("general.copied")
      });
    },
    updateStore(partnerId) {
      Partners.get(partnerId)
        .then(response => {
          store.dispatch(UPDATE_SELECTED_PARTNER, response.data.data);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    }
  }
};
</script>
