<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("partner.contactData") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary" @click="saveData">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactWebsite") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-link icon-lg" />
                </span>
              </div>
              <input
                v-model="partner.additional_data.contact.website"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactEmail") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-at icon-lg" />
                </span>
              </div>
              <input
                v-model="partner.additional_data.contact.email"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactPhone") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-phone icon-lg" />
                </span>
              </div>
              <input
                v-model="partner.additional_data.contact.phone"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="form-group row align-items-center">
          <label class="col-xl-3 col-lg-3 col-form-label">
            {{ $t("partner.contactFax") }}
          </label>
          <div class="col-md-9 col-lg-6 col-xl-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-fax icon-lg" />
                </span>
              </div>
              <input
                v-model="partner.additional_data.contact.fax"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Partner from "@/components/Admins/Partners/partners";
import { UPDATE_SELECTED_PARTNER } from "@/core/services/store/switchArea.module";
import store from "@/core/services/store";

export default {
  props: ["partner"],
  data() {
    return {
      isBusy: false
    };
  },
  methods: {
    saveData() {
      this.isBusy = true;
      const data = {
        name: this.partner.name,
        additional_data: this.partner.additional_data
      };
      let partnerId = this.partner.id;
      Partner.update(partnerId, data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("partner.partnerUpdated")
          });
          this.updateStore(partnerId);
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    updateStore(partnerId) {
      Partner.get(partnerId)
        .then(response => {
          store.dispatch(UPDATE_SELECTED_PARTNER, response.data.data);
        })
        .catch(error => {
          this.$swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
        });
    }
  }
};
</script>
